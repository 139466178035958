/**
 * Reset Password
*/
import React, { Component } from 'react';
import { userService } from '_services/user.service';
import { TextField, Button, Box, Typography, Divider } from "@material-ui/core";
import { CustomCard } from 'components/GlobalComponents';
import { Link } from 'react-router-dom';




class ResetPasswordFirebase extends Component {
    	//constructor
	constructor(props) {
		super(props);
		this.state = {
			formErrors: {
				blankPassword: false,
                blankconfirmePassword:false,
                validPassword:false,
                notMatch:false,
                lengthError:false
			},
			value: 0,
		}
	}
    
	/**
	 * Function to detect login password changes
	 */
	handleChange(e) {
        this.setState({
            formErrors: {                   
                ...this.state.formErrors,   
                blankPassword: false,
                blankconfirmePassword:false,
                validPassword:false,
                notMatch:false,
                lengthError:false     
            }
        })
		this.setState({ [e.target.name]: e.target.value });
	}
    confirm(){
        this.validation()
        if(this.state.formErrors.validPassword){
            this.resetPassword()
        }
    }
    resetPassword(){
        const urlParams = new URLSearchParams(window.location.search)
        const object={
            user_id:urlParams.get('id'),
            token:urlParams.get('token'),
            password:this.state.password
        }
        userService.resetPassword(object).then(res=>{
            this.props.history.push('/signin');
        })
    }
    validation(){
        if (!this.state.password) {
            this.setState({
                formErrors: {                   
                    ...this.state.formErrors,   
                    blankPassword: true       
                }
            })
        }
        if (!this.state.Confirmpassword) {
            this.setState({
                formErrors: {                   
                    ...this.state.formErrors,   
                    blankconfirmePassword: true       
                }
            })
        }
        if (this.state.password.length > 8  ){ 
            if (this.state.password==this.state.Confirmpassword ){
                this.setState({
            formErrors: {                   
                ...this.state.formErrors,   
                validPassword: true }})
            }
            else{
                this.setState({
                    formErrors: {                   
                        ...this.state.formErrors,   
                        notMatch: true }})
            }
        }
        else{
            this.setState({
                formErrors: {                   
                    ...this.state.formErrors,   
                    lengthError: true }})
        }
    }
	render() {
        const { blankconfirmePassword, blankPassword,notMatch,lengthError } = this.state.formErrors;
		return (
			<div>
				<div className="session-wrapper session-wrapper-v2">
					<Box className="session-box" mx="auto" display="flex" justifyContent="center" alignItems="center">
						<Box width="100%">
							<Box textAlign="center" className="session-logo" >
								{this.props.isDarkModeActive ?
									<div style={{ color: "#161616", fontSize: "20px", fontWeight: "700" }}>MULTICOUNTRY <span style={{ color: "#00AD62" }}>SH</span></div>

									:
									<div style={{ color: "#161616", fontSize: "20px", fontWeight: "700" }}>MULTICOUNTRY <span style={{ color: "#00AD62" }}>SH</span></div>

								}
							</Box>
							<CustomCard>
								<form className="login-form text-center">
									<Typography variant="h6" color="textPrimary" className="title">Reset Your Password?</Typography>
									<Typography variant="body2" color="textSecondary" className="subtitle">Not to worry, we got you! let's get you a new password</Typography>
									<Box my={2}>
										<TextField
											required
											fullWidth
											variant="outlined"
											id="username"
											type="password"
											name="password"
											placeholder="Please enter your new password."
											className="outlined-input"
                                            onChange={(event)=>this.handleChange(event)}
										/>
                                        {blankPassword &&
												<Box component="span" color="error.main" textAlign="left" display="block" fontSize="subtitle2.fontSize" pt={1}>Password cannot be empty.</Box>
										}
										{ !blankPassword && lengthError  &&
												<Box component="span" color="error.main" textAlign="left" display="block" fontSize="subtitle2.fontSize" pt={1}>The Password must be more than 8 caracters.</Box>
										} 
									</Box>
                                    <Box my={2}>
										<TextField
											required
											fullWidth
											variant="outlined"
											id="username"
											type="password"
											name="Confirmpassword"
											placeholder="Please confirm your new password."
											className="outlined-input"
                                            onChange={(event)=>this.handleChange(event)}
										/>
                                    {blankconfirmePassword &&
											<Box component="span" color="error.main" textAlign="left" display="block" fontSize="subtitle2.fontSize" pt={1}>Confirm Password cannot be empty.</Box>
									}
									{ !blankconfirmePassword && notMatch &&
											<Box component="span" color="error.main" textAlign="left" display="block" fontSize="subtitle2.fontSize" pt={1}>Password and Confirm Password not similar .</Box>
									} 
									</Box>
									<Box py={3}>
										<Button
											color="primary"
											className="btn-block blockBtn w-100"
											variant="contained"
											size="large"
                                            onClick={()=>this.confirm()}
										>
											Submit
										</Button>
									</Box>
									<Divider></Divider>
									<Box pt={2} fontSize="subtitle2.fontSize">
										<Box style={{ cursor: 'pointer' }} color="primary.main" component={Link} to="/signin" >Back To Sign In</Box>

									</Box>
								</form>
							</CustomCard>
						</Box>
					</Box>
				</div>
			</div>
		);
	}
}

// const mapStateToProps = ({ authUser, settings }) => {
//     console.log(authUser)
// 	const { password,Confirmpassword, error } = authUser;
// 	const { isDarkModeActive } = settings;
// 	return { password,Confirmpassword, error, isDarkModeActive };
// };

// export default connect(mapStateToProps, {onPasswordChanged,onConfirmPasswordChanged})(ResetPasswordFirebase);
export default ResetPasswordFirebase;