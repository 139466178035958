/**
 * Forget Password
*/
import React, { Component } from 'react';
import { userService } from '_services/user.service';
import { connect } from 'react-redux';
import { TextField, Button, Box, Typography, Divider } from "@material-ui/core";
import { CustomCard } from 'components/GlobalComponents';
import { Link } from 'react-router-dom';


class ForgotPasswordFirebase extends Component {
	//constructor
	constructor(props) {
		super(props);
		this.state = {
			formErrors: {
				blankEmail: false,
				invalidEmail: false,
				sendemail: false,
				errorSend:false
			},
			value: 0
		}
	}

	validateEmail(email) {
		let emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
		return emailValid;
	}


	RequestResetPassword(e) {
		
		let  email = !this.state.email?'':this.state.email;
		let fieldValidationErrors = this.state.formErrors;
		if(email==''){	
			this.setState({
				formErrors: {                   
					...this.state.formErrors,   
					blankEmail: true     
				}
			})}
		if(email){
			if(this.validateEmail(email)==null){
				fieldValidationErrors.invalidEmail=true}
			else{this.RequestPassword(email)}
		this.setState({ submitted: true });
		this.setState({ formErrors: fieldValidationErrors })
		}
	}
	handleChange(e) {
		let fieldValidationErrors = this.state.formErrors;
		fieldValidationErrors.blankEmail = false;
		fieldValidationErrors.invalidEmail = false;
		fieldValidationErrors.sendemail = false;
		fieldValidationErrors.errorSend = false;
		this.setState({ formErrors: fieldValidationErrors })
		this.setState({ [e.target.name]: e.target.value });
	}

	RequestPassword(email){
			userService.resetPasswordRequest(email).then(user => { 
				this.setState({
					formErrors: {                   
						...this.state.formErrors,   
						sendemail: true     
					}
				})

			}).catch((error) => {
				this.setState({
					formErrors: {                   
						...this.state.formErrors,   
						errorSend: true     
					}
				})
			})

	};


	render() {
		const { blankEmail, invalidEmail,sendemail,errorSend } = this.state.formErrors;
		const {  isDarkModeActive } = this.props;
		return (
			<div>
				<div className="session-wrapper session-wrapper-v2">
					<Box className="session-box" mx="auto" display="flex" justifyContent="center" alignItems="center">
						<Box width="100%">
							<Box textAlign="center" className="session-logo" >
								{isDarkModeActive ?
									<div style={{ color: "#161616", fontSize: "20px", fontWeight: "700" }}>MULTICOUNTRY <span style={{ color: "#00AD62" }}>SH</span></div>

									:
									<div style={{ color: "#161616", fontSize: "20px", fontWeight: "700" }}>MULTICOUNTRY <span style={{ color: "#00AD62" }}>SH</span></div>

								}
							</Box>
							<CustomCard>
								<form className="login-form text-center">
									<Typography variant="h6" color="textPrimary" className="title">Forgot Your Password?</Typography>
									<Typography variant="body2" color="textSecondary" className="subtitle">Not to worry, we got you! let's get you a new password</Typography>
									<Box my={2}>
										<TextField
												required
												fullWidth
												variant="outlined"
												id="username"
												type="email"
												name="email"
												placeholder="Please enter your email address."
												className="outlined-input"
												//value={email}
												onChange={(event)=>this.handleChange(event)}
											
										/>
											{blankEmail &&
												<Box component="span" color="error.main" textAlign="left" display="block" fontSize="subtitle2.fontSize" pt={1}>Email cannot be empty.</Box>
											}
											{!blankEmail && invalidEmail &&
												<Box component="span" color="error.main" textAlign="left" display="block" fontSize="subtitle2.fontSize" pt={1}>The email address is badly formatted.</Box>
											}
										
										  {sendemail &&
												<Box component="span" color="primary.main" textAlign="left" display="block" fontSize="subtitle2.fontSize" pt={1}>Email send it please chech your email</Box>
											}
										{errorSend &&
												<Box component="span" color="error.main" textAlign="left" display="block" fontSize="subtitle2.fontSize" pt={1}>Email does not exist</Box>
											}
									</Box>
									<Box py={3}>
										<Button
											color="primary"
											className="btn-block blockBtn w-100"
											variant="contained"
											size="large"
											onClick={(e) => this.RequestResetPassword(e)}
										>
											Reset My Password
										</Button>
									</Box>
									<Divider></Divider>
									<Box pt={2} fontSize="subtitle2.fontSize">
										<Box style={{ cursor: 'pointer' }} color="primary.main" component={Link} to="/signin" >Back To Sign In</Box>

									</Box>
								</form>
							</CustomCard>
						</Box>
					</Box>
				</div>
			</div>
		);
	}
}

export default ForgotPasswordFirebase;